import * as React from 'react'

const SteamIcon = () => {
  return (
    <svg viewBox="0 0 24 24" width="100%" height="100%" color="currentColor" transition=".5s">
      <path
        fill="currentColor"
        stroke="#000000"
        stroke-width="1px"
        stroke-linejoin="round"
        d="m17.903 1.75c-3.349 0-6.081 2.696-6.107 6.02l-3.803 5.427c-1.061-.096-1.976.123-2.785.614l-5.208-2.097v5.727l3.08 1.233c.445 2.039 2.278 3.576 4.447 3.576 2.358 0 4.312-1.803 4.527-4.099l5.855-4.259c3.359 0 6.091-2.711 6.091-6.073l-.001-.001c0-3.346-2.732-6.068-6.096-6.068zm-13.334 17.521c.546.208 1.098.437 1.645.651 1.367.56 2.92-.107 3.466-1.468.552-1.361-.118-2.897-1.483-3.442l-1.393-.56c2.074-.46 4.071 1.094 4.072 3.255-.001 1.852-4.678 4.608-6.307 1.564zm13.34-7.385c-2.261 0-4.098-1.815-4.098-4.067 0-2.242 1.832-4.067 4.098-4.067 2.245 0 4.082 1.825 4.082 4.067 0 2.258-1.838 4.073-4.082 4.067z"
      />
      <path
        fill="currentColor"
        stroke="#000000"
        stroke-width="1px"
        stroke-linejoin="round"
        d="m17.92 4.556c-1.805 0-3.267 1.457-3.267 3.255s1.457 3.255 3.267 3.255c1.794 0 3.267-1.452 3.267-3.255s-1.473-3.255-3.267-3.255z"
      />
    </svg>
  )
}

export default SteamIcon
