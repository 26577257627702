import * as React from 'react'

const CloseIcon = () => {
  return (
    <svg viewBox="0 0 38 38" width="100%" height="100%" color="currentColor" transition=".5s">
      <path
        d="M37.4769 3.53553C37.8674 3.14501 37.8674 2.51184 37.4769 2.12132L36.0627 0.707107C35.6722 0.316583 35.039 0.316583 34.6485 0.707107L19.7993 15.5563C19.4087 15.9468 18.7756 15.9468 18.385 15.5563L3.53585 0.707112C3.14532 0.316588 2.51216 0.316588 2.12163 0.707112L0.707418 2.12133C0.316894 2.51185 0.316894 3.14501 0.707418 3.53554L15.5566 18.3847C15.9471 18.7753 15.9471 19.4084 15.5566 19.799L0.707351 34.6482C0.316827 35.0388 0.316827 35.6719 0.707351 36.0624L2.12156 37.4767C2.51209 37.8672 3.14525 37.8672 3.53578 37.4767L18.385 22.6274C18.7756 22.2369 19.4087 22.2369 19.7993 22.6274L34.6485 37.4767C35.0391 37.8672 35.6722 37.8672 36.0628 37.4767L37.477 36.0625C37.8675 35.6719 37.8675 35.0388 37.477 34.6482L22.6277 19.799C22.2372 19.4084 22.2372 18.7753 22.6277 18.3847L37.4769 3.53553Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CloseIcon
